<template>
    <div class="main-container">
        <div class="title-container">
          <h1>Carrito de la compra</h1>
        </div>
        <div v-if="this.pop_up_delete" id="popup-container" class="popup-style-container">
          <div id="popup-modal" class="popup-modal">
            <div class="logo-eurekers">
              <img src="../assets/img/eurekers.jpg" title="logo-eurekers" width=50 height=50/ />
            </div>
            <div id="popup-form-container" class="popup-form-container">
              <div id="text-container" class="text-container">
                <h3>¿Seguro que quiere eliminar este producto del carrito?</h3>
              </div>
              <div class="buttons-container">
                <Button id="cancel-delete" 
                @_emit_method_button="_cancelDelete"  
                button_icon='cancel-delete-icon'
                button_name="Cancelar" 
                button_key='cancel-delete-key' 
                button_icon_id='cancel-delete-icon-id' />
                <Button id="confirm-delete" 
                @_emit_method_button="_confirmDelete"  
                button_icon='confirm-delete-icon' 
                button_name="Eliminar" 
                button_key='confirm-delete-key' 
                button_icon_id='confirm-delete-icon-key' />
              </div>
            </div>
          </div>
        </div>
        <div v-if="products.length != 0" class="non-empty-cart-container">
          <div v-for="(product, index) in products" :key="index" class="cart-products-container">
            <div class="product">
              <h2>{{ product.product_name }}</h2>
              <div class="quantity-wrapper">
                <div class="quantity-text">
                  <strong>Cantidad:</strong>
                </div>
                <div class="product-quantity-container">
                  <div class="">
                    <button class="manage-product-quantity-btn" @click="_modifyQuantity(index, -1)"><span class="material-symbols-outlined">do_not_disturb_on</span></button>
                  </div>
                  <div class="">
                    {{ product.quantity }}
                  </div>
                  <div class="">
                    <button class="manage-product-quantity-btn" @click="_modifyQuantity(index, 1)"><span class="material-symbols-outlined">add_circle</span></button>
                  </div>
                </div>
              </div>
              <p>
              </p>
              <p><strong>Precio unitario:</strong> {{ product.price }}€</p>
              <p><strong>Total:</strong> {{ product.quantity * product.price }}€</p>
              <button class="remove-product" @click="_deleteProductPreConfirmation(index)">
                Eliminar producto
                <span class="material-symbols-outlined">delete</span>
              </button>
            </div>
          </div>
          <div class="cart-price-container">
            <p>Total: <span class="price">{{ amount }}€</span></p>
          </div>
          <div class="btns-container">
            <button class="buy-btn" @click="_sendPurchaseOrder()">Comprar</button>
            <button class="back-btn" @click="_continueShopping()">Volver</button>
          </div>
        </div>
        <div v-else class="empty-cart-container">
          <div class="empty-cart-image-container">
            <img src="@/views/assets/empty-cart.png" alt="Empty Cart">
          </div>
          <div class="empty-cart-text-container">
            <p class="empty-cart-text">¡Tu carrito de la compra está vacío!</p>
            <p class="add-products-text">Añade productos al carrito</p>
          </div>
          <div class="continue-shopping-container">
            <a href="/app/store" class="continue-shopping-link">Continuar comprando</a>
          </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/helpers/Button.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'Cart',
  components: {
    Button,
  },
  data: () => ({
    pop_up_delete: false,
    index_product: "",
    amount: 0,
    products: [],
  }),

  mounted() {
    this._getProducts();
  },
  created: function() {

  },
  computed: {

  },
  methods: {

    _getProducts: function(){
      let success = response => {
        this.products = [];
        this.amount = response.data.value.amount;
        let prod = response.data.value.products;
        for(let key in prod){
          this.products.push(prod[key]);
        }
      }

      let url = "/api/v1/eurekers_marketplace/get-cart";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('510', 'Error al recuperar los productos.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _removeProduct(index){
      this.products.splice(index, 1);
    },

    _modifyQuantity(index, cantidad) {
      if(cantidad == 1){
        this.$vueOnToast.pop('success', "Producto añadido al carrito con éxito.");
      } else {
        this.$vueOnToast.pop('success', "Producto eliminado del carrito con éxito.");
      }
      if (parseInt(this.products[index].quantity) + cantidad > 0) {
        this.products[index].quantity = parseInt(this.products[index].quantity) + cantidad;
      } else{
        this.index_product = index;
        this.pop_up_delete = true;
      }
      this._modifyCart(this.products[index]);
    },

    _deleteProductPreConfirmation: function(index){
      this.index_product = index;
      this.pop_up_delete = true;
    },

    _sendPurchaseOrder(){
      window.location.href="/stripe/pay-shoping-cart";
    },

    _continueShopping(){
      window.location.href="/app/store";
    },
    _cancelDelete: function(){
      this.index_product = "";
      this.pop_up_delete = false;
    },
    _confirmDelete: function(){
      this.products[this.index_product].quantity = 0;
      this._modifyCart(this.products[this.index_product]);
      this._removeProduct(this.index_product);
      this.index_product = "";
      this.pop_up_delete = false;
    },
    _modifyCart: function(prod){
      let params = new URLSearchParams();
      params.append('product_name', prod.product_name);
      params.append('product_sku', prod.product_sku);
      params.append('price', prod.price);
      params.append('currency', prod.currency);
      params.append('consumible_type', "unico");
      params.append('quantity', prod.quantity);
      let success = ()=>{
        this._getProducts();
      }

      let url = "/api/v1/eurekers_marketplace/edit-cart";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('235', 'Error actualizando información de usuario.');

      this._postAPICall(url, params, successHandler, failureHandler);
    },
  },
}

</script>

<style lang="scss" scoped>

  h1 {
    margin: 0;
    padding-top: 20px;
    color: var(--color-text-primary);
  }

  .main-container{
    height: 100%;
    width: 100%;
  }

  .title-container{
    margin-left: 10px;
  }

  .empty-cart-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }

  .quantity-wrapper{
    display: flex;
    align-items: center;
  }

  .product-quantity-container{
    display: flex;
    align-items: center;
  }

  .empty-cart-text,
  .add-products-text{
    color: grey;
  }

  .empty-cart-text{
    font-weight: 500;
  }

  .add-products-text{
    font-size: 12px;
  }

  .continue-shopping-link{
    background-color: #0aadd9;
    color: white;
    border-radius: 25px;
    padding: 10px;
  }

  .continue-shopping-container{
    margin-top: 20px;
  }

  .cart-products-container{
    margin-left: 10px;
  }

  .product{
    border: 1px solid grey;
    width: 80%;
    margin-top: 15px;
    border-radius: 10px;
    padding: 5px;
  }

  .product * {
    color: var(--color-text-primary);
  }

  .manage-product-quantity-btn{
    background-color: transparent;
    color: #0aadd9;
    border: none;
    cursor: pointer;
  }

  .remove-product{
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: #0aadd9;
    border-radius: 15px;
    color: white;
    padding: 10px;
  }

  .cart-price-container * {
    color: var(--color-text-primary);
  }

  .cart-price-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cart-price-container .price{
    font-size: 25px;
    font-weight: bold;
  }

  .btns-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .back-btn{
    cursor: pointer;
    border: none;
    background-color: #0aadd9;
    border-radius: 15px;
    color: white;
    padding: 10px;
    width: 100px;
  }

  .buy-btn{
    cursor: pointer;
    border: none;
    font-size: 20px;
    background-color: orange;
    color: black;
    border-radius: 15px;
    padding: 10px;
    width: 150px;
  }

  .popup-style-container{
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
  }
  .popup-modal{
    position: absolute;
    top: 30%;
    left: 30%;
    z-index: 1002;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    padding: 10px;
    width: 585px;
    height: 230px;
    animation: fadeIn 1s;
    display: inline-flex;
  }
  .buttons-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20%;
  }


</style>